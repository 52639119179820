// Constants
$primaryGray: #333333;
$primaryPurple: #9E7AE9;
$primaryWhite: #FFF;
$inDev: false;

// Ternaries
@mixin inDev($backgroundColor) {
    @if $inDev {
        background-color: $backgroundColor;
    }

    @else {
        background-color: null;
    }
}

@mixin backgroundHeight($height) {
    @if $height {
        height: 1000px;
    }

    @else {
        height: 1050px;
    }
}

// Footer
.footerDiv {
    width: 100%;
    background-color: $primaryPurple;
    height: 30px;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    display: flex;
}

.footerDiv a {
    color: $primaryWhite;
    font-family: 'circeRegular';
    display: flex;
    font-size: .7rem;
    position: absolute;
    text-decoration: none;
}

.footerDiv a:hover {
    text-decoration: underline;
}

.footerDiv p {
    color: $primaryWhite;
    font-family: 'circeBold';
    display: flex;
    font-size: .7rem;
}

// Welcome
.background {
    width: 100%;
    @include backgroundHeight(true);
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.mobileView {
    display: none;
}

.welcomeDiv {
    @include inDev(blue);
    width: 72%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    max-width: 1000px;
    flex-direction: row;
    margin-top: 84px;
    position: relative;
}

.leftDiv {
    width: 50%;
    @include inDev(purple);
    flex-direction: row;
}

.threeDivs {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    @include inDev(orange);
    height: 230px;
}

.threeDivs img {
    height: 64px;
}

.textDiv {
    @include inDev(blue);
}

.textTitle {
    color: $primaryWhite;
    font-family: 'circeBold';
    margin: 0;
    font-size: 2.1rem;
    margin-bottom: 12px;
}

.textBody {
    color: $primaryWhite;
    font-family: 'circeRegular';
    margin: 0;
    font-size: 1.2rem;
}

.downloadIcons {
    width: 159px;
}

.rightDiv {
    width: 50%;
    @include inDev(red);
    justify-content: flex-end;
    display: flex;
}

.rightDiv img {
    height: 690px;
    display: flex;
}

@media only screen and (min-height: 1000px) {
    .background {
        height: 100vh;
    }
}

@media only screen and (max-width: 1150px) {
    .background {
        width: 100%;
        @include backgroundHeight(false);
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
    }

    .welcomeDiv, .iPhone {
       visibility: hidden;
    }

    .mobileView {
        @include inDev(blue);
        visibility: visible;
        width: 80%;
        display: flex;
        align-content: center;
        justify-content: center;
        position: absolute;
        margin-top: 52px;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .textDiv {
        width: 90%;
        @include inDev(green);
        justify-content: center;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin-top: 24px;
        text-align: center;
    }

    .textT {
        color: $primaryWhite;
        font-size: 1.6rem;
        font-family: 'circeBold';
        margin: 0;
        margin-bottom: 4px;;
    }

    .textB {
        color: $primaryWhite;
        font-size: 1rem;
        font-family: 'circeRegular';
        margin: 0;
        text-align: center;
        margin-bottom: 56px;
    }

    .imgDiv {
        visibility: visible;
        @include inDev(blue);
        justify-content: center;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .iPhone2Div {
        display: flex;
        @include inDev(red)
    }

    .iPhone2 {
        height: 516px;
        margin-bottom: 56px;
        display: flex;
    }

    .downloadDiv {
        @include inDev(orange);
        flex-wrap: wrap;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .download {
        visibility: visible;
        display: flex;
        height: 48px;
    }
}
