body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  background-color: #333333;
  overscroll-behavior-y: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'circeBold';
  src: local('Circe-Bold'), url(./Fonts/Circe-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'circeRegular';
  src: local('Circe-Regular'), url(./Fonts/Circe-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'circeLight';
  src: local('Circe-Light'), url(./Fonts/Circe-Light.ttf) format('truetype');
}